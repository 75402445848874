import React from "react";
import { ExperienceDiv, Explain, ImportantParts, Title } from "./ExperienceStyle";

export const Experiences = ()=>{
    return(
        <ExperienceDiv>
            <Title>
                My Experiences
            </Title>
            <Explain>
                -I have worked as Software Engineer position for 7 months (Intern + Part-time) in <a href="https://www.baykartech.com/en/">Baykar Technology</a>.
                We worked as a team to develop interfaces for UAV. 
                I have developed my programming skills and learnt to use various inter-proccess communication (IPC) methods.
            </Explain>
            <Explain>
                -I was an intern in <a href="https://www.pixelsoftoffice.com/default.aspx">Pixel Soft Office</a> for two months. I mostly worked on website 
                backend services while I was in this internship. I learnt a lot of things from it, like using <ImportantParts>SQL</ImportantParts> in advanced and dynamic ways as a database and
                using <ImportantParts>C#</ImportantParts> for API.
            </Explain>         
        </ExperienceDiv>
    )
}