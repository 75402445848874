import React from "react";
import { AboutMe } from "./components/AboutMe/AboutMe";
import { ContainerDiv, LastDiv, MyImg, NameTitle, TypewriterDiv } from "./HomePageElements";
import pic from "../Assets/myPhoto.jpg" 
import { Experiences } from "./components/Experiences/Experiences";
import Typewriter from "typewriter-effect";
import { ContactMe } from "./components/ContactMe/ContactMe";

export const HomePage = ()=>{

    return(
    <ContainerDiv>
       
        <MyImg src={pic} ></MyImg>
        <NameTitle>Ali Vehbi Guneysu</NameTitle>
        <TypewriterDiv>
        <Typewriter 
        options={{
            autoStart:true,
            loop:true,
            delay:40,
            strings:["I am a Cyber Security Engineer...",
            "I am a Computer Scientist...",
            "I am a Software Engineer...",
        ]
            
            
        }}>
        </Typewriter>
        </TypewriterDiv>
        <AboutMe/>
        <Experiences/>
        <ContactMe/>
        
        <LastDiv></LastDiv>
    </ContainerDiv>
        )
}