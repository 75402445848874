import React from "react";
import { ContactMeDiv, Email1, Email2, Emp, GitHub, Image1, LinkedIn, Title } from "./ContactStyle";
import pic1 from "./contact.png"

export const ContactMe = ()=>{
    return(
        <ContactMeDiv>
            <Image1 src={pic1}></Image1>
            <Title>
                Contact Me
            </Title>
            <Email1><Emp>Email 1:</Emp> alivehbiguneysu@gmail.com</Email1>
            <LinkedIn  rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/ali-vehbi-g%C3%BCneysu-42346b219/"><Emp>LinkedIn:</Emp>click to go linkedin-ali-vehbi-güneysu</LinkedIn>
            <GitHub  rel="noreferrer" target="_blank" href="https://github.com/alivehbi"><Emp>GitHub:</Emp>https://github.com/alivehbi</GitHub>

        </ContactMeDiv>
    )
}