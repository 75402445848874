import { AboutMeDiv, Explain, Title } from "./AboutStyle";
import React from "react";
// import { collection, getDocs } from "firebase/firestore";
// import {db} from '../../../firebase';

export const AboutMe = ()=>{
    // const [about,setAbout] = useState("")

    // useEffect(()=>{
    //         getDocs(collection(db, "homepage"))
    //         .then((querySnapshot)=>{               
    //             const newData = querySnapshot.docs
    //                 .map((doc) => ({...doc.data(), id:doc.id }));
    //             newData.forEach(element => {
    //                 if(element.title === "aboutme"){
    //                     setAbout(element.explanation)
    //                 }
                    
    //             });
    //         })
    // }, [])
    return(
        <AboutMeDiv>
            <Title>
                About Me...
            </Title>
            <Explain>
            MSc Cyber Security student in University of Kent. I really interested cyber security, especially malware analysing and ethical hacking.
             I have numerous projects on various software area, see them in the <a href="/projects">project</a> page.
            </Explain>
        </AboutMeDiv>
    )
}